import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from '@betalpha/router';
import { replace } from 'lodash/fp';
import { useTitle } from 'ahooks';
import { allBondFundsMapSelector } from '@/selectors/bond-funds';
import Page from '@/components/page';
import ActionBar from '@/components/action-bar';
import NoticeBar from '@/components/notice-bar';
import { BOND_FUND } from '@/const';
import Share from '@/components/share';
import { useSearchPopup } from '@/components/action-bar/hooks';
import BasicInfo from './components/basic-info';
import PerformanceTrend from './components/performanceTrend';
import Ability from './components/ability';
import Dismantling from './components/dismantling';

export default React.memo(() => {
  useTitle('SFinX | 债基稳斗士', { restoreOnUnmount: true });
  const { fundId } = useParams();
  const fundMap = useSelector(allBondFundsMapSelector);
  const fund = useMemo(() => fundMap?.[fundId || ''], [fundMap, fundId]);
  const searchNode = useSearchPopup('BOND_FUND');

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, [fundId]);

  return (
    <Page
      type="BOND_FUND"
      needFund
      actionBar={
        <ActionBar
          title={fund?.fundName}
          rootPath="/"
          subTitle={fund?.fundId ? `(${replace('_OF')('')(fund?.fundId)})` : ''}
          extra={
            <>
              {searchNode}
              {fund ? <Share PDFType="BOND_FUND" /> : null}
            </>
          }
        />
      }
    >
      {fund ? (
        <>
          <NoticeBar type={BOND_FUND} />
          <BasicInfo />
          <PerformanceTrend />
          <Ability fundName={fund?.fundName} />
          <Dismantling fundName={fund?.fundName} />
        </>
      ) : null}
    </Page>
  );
});
