import React, { useMemo } from 'react';
import { getMessage } from '@betalpha/intl';
import { useParams } from '@betalpha/router';
import { mapIndexed, normalize } from '@betalpha/stdlib/base';
import { flow, map, prop, isEmpty, compact } from 'lodash/fp';
import { FactorRatingItemType } from '@/interface/fundDetail';
import CardLayout from '@/components/cardLayout';
import { useGetBondAbilitySummaryQuery } from '@/infra/api/bond';
import EvaluationDetailTrigger from '@/components/evaluation-detail-trigger';
import Tabs, { Tab } from '@/components/tabs';
import { marketTabItem } from '@/views/fund-compass-home/contants';
import Rate from '../../../../components/rate';
import { abilityTabItems, factorRatingsOrder, FundInfo } from '../../constants';
import style from './index.module.less';
import AbilitySummary from './abilitySummary';

export default React.memo<FundInfo>(({ fundName }) => {
  const { fundId } = useParams();
  const { data: evaluationReportAbilitySummary } = useGetBondAbilitySummaryQuery(fundId || '', {
    skip: !fundId
  });

  const { factorRatings } = evaluationReportAbilitySummary ?? { factorRatings: [] };
  const factorRatingMap = normalize(factorRatings, 'factorName');
  const factorRatingCodeMap = normalize(factorRatings, 'factorCode');

  const sortedFactorRatingsTabItems = useMemo(
    () =>
      ((mapper) => map((factorCode: string) => mapper[factorCode])(factorRatingsOrder))(
        factorRatingCodeMap
      ) as FactorRatingItemType[],
    [factorRatingCodeMap]
  );

  const isEmptyTabs = useMemo(() => flow(compact, isEmpty)(sortedFactorRatingsTabItems), [sortedFactorRatingsTabItems]);

  return (
    <CardLayout
      title={
        <div className={style.TitleWrap}>
          <div>{getMessage('abilitySummary')}</div>
          <Rate value={prop('totalRating')(evaluationReportAbilitySummary)} />
        </div>
      }
      loading={false}
    >
      <div className={style.AbilitySummaryWrap}>
        <AbilitySummary factorRatingNameMap={factorRatingMap} sortedFactorRatings={sortedFactorRatingsTabItems} />
        {!isEmptyTabs && (
          <EvaluationDetailTrigger
            initalizeHeight={10}
            text={getMessage('evaluationDetails')}
            collapseText={getMessage('evaluationDetails')}
          >
            <Tabs className={style.Tab} defaultActiveKey="RETURN">
              {mapIndexed((item: marketTabItem, idx: number) => (
                <Tab
                  key={item?.id || idx}
                  title={factorRatingCodeMap[item?.id].factorName}
                  description={<Rate className={style.Rate} value={factorRatingCodeMap[item?.id]?.fundFactorRating} />}
                >
                  <item.component fundName={fundName} />
                </Tab>
              ))(abilityTabItems)}
            </Tabs>
          </EvaluationDetailTrigger>
        )}
      </div>
    </CardLayout>
  );
});
