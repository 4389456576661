import React, { useMemo } from 'react';
import { merge, set, isEmpty } from 'lodash/fp';
import { EChartsOption, SeriesOption } from 'echarts';
import { percentage } from '@betalpha/stdlib/numberFormatter';
import { mapIndexed } from '@betalpha/stdlib/base';
import { getCumulativeReturns } from '@betalpha/stdlib/quant';
import { getMessage } from '@betalpha/intl';
import EmptyContent from '@/components/emptyContent';
import { CHART_COLOR, getAxisOptionByDate } from './constant';
import BaseChart from './baseChart';
// import { getAxisOptionByAverageDisplayDate } from './constant';

export function cumulativeReturn(returns?: number[]) {
  if (!returns) return [];
  return getCumulativeReturns(set(0, 0)(returns), true);
}

interface PerformanceChartProps {
  dates?: string[];
  option: EChartsOption;
  height?: number;
}

const useChartOption = ({ option, dates }: PerformanceChartProps) => {
  const dateOption = useMemo(() => getAxisOptionByDate(dates || []), [dates]);
  const chartOptions = useMemo<EChartsOption>(
    () =>
      merge({
        legend: {
          icon: 'path://M30 0 L200 0 Q 230 30 200 60 L30 60 Q 0 30 30 0 Z',
          itemHeight: 4,
          itemWidth: 11,
          itemGap: 12,
          bottom: 0,
          top: 16,
          left: 'left',
          padding: 0,
          show: true,
          textStyle: { color: '#666666', fontSize: 10 }
        },
        title: {
          text: ''
        },
        tooltip: {
          show: false
        },
        grid: { left: 'left', top: 48, bottom: 0, right: 4, containLabel: true },
        xAxis: merge({
          type: 'category',
          position: 'bottom',
          data: dates,
          boundaryGap: false,
          axisLabel: {
            show: true,
            margin: 14,
            color: '#666666',
            // formatter: (v: string) => dayjs(v).format('YYYY年'),
            hideOverlap: false,
            // interval: (_: number, value: string) => fastProp(value)(yearOfDates),
            fontFamily: 'DINAlternate',
            fontSize: 10,
            fontWeight: 400,
            showMaxLabel: true,
            showMinLabel: true
          },
          show: true,
          axisLine: {
            onZero: true,
            show: true,
            lineStyle: {
              color: '#333'
            }
          },
          axisTick: {
            show: false,
            lineStyle: {
              color: '#333'
            }
          }
        })(dateOption),
        yAxis: {
          splitNumber: 2,
          type: 'value',
          splitLine: {
            show: true,
            lineStyle: { color: '#ECECEC', type: 'dotted' }
          },
          axisLabel: {
            color: '#666',
            fontFamily: 'DINAlternate',
            formatter: (val: number) => percentage(val, { digit: 0 }),
            fontWeight: 400,
            fontSize: 10
          }
        }
      })({
        ...option,
        series: mapIndexed((serie: SeriesOption, index: number) =>
          merge({
            color: CHART_COLOR?.[index],
            type: 'line',
            smooth: false,
            showSymbol: false,
            lineStyle: {
              width: 1
            }
          })(serie)
        )(option?.series)
      }),
    [dates, option, dateOption]
  );
  return chartOptions;
};

export default React.memo<PerformanceChartProps>(({ height, option, dates }) => {
  const chartOptions = useChartOption({ option, dates });
  const empty = useMemo(() => isEmpty(dates), [dates]);
  const resultHeight = height || 180;
  if (empty) {
    return (
      <EmptyContent style={{ height: resultHeight }}>
        <strong>{getMessage('noData')}</strong>
      </EmptyContent>
    );
  }
  return <BaseChart option={chartOptions} height={height} />;
});
