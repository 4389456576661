import { size } from 'lodash/fp';

export const CHART_COLOR = ['#1A396A', '#CFB47F', '#9CACBC', '#7494B4', '#74A5B4'];

export function getAxisOptionByDate(dates: string[]) {
  return {
    axisLabel: {
      interval: Math.floor(size(dates) / 2),
      formatter(value: string, idx: number) {
        if (idx === size(dates) - 1) {
          return `{alignRightLabel|${value}}`;
        }
        if (idx === 0) {
          return `{alignLeftLabel|${value}}`;
        }
        return value;
      },
      rich: {
        alignRightLabel: {
          fontFamily: 'DINAlternate',
          color: '#666',
          padding: [0, 48, 0, 0],
          fontWeight: 400,
          fontSize: 10
        },
        alignLeftLabel: {
          fontFamily: 'DINAlternate',
          color: '#666',
          padding: [0, 0, 0, 48],
          fontWeight: 400,
          fontSize: 10
        }
      }
    }
  };
}
