import { useParams } from '@betalpha/router';
import React, { useMemo } from 'react';
import { isNil, map } from 'lodash/fp';
import { getMessage } from '@betalpha/intl';
import CardLayout from '@/components/cardLayout';
import { ReactComponent as rateSvg } from '@/assets/bondEvaluationReport/rate.svg';
import { ReactComponent as fundTypeSvg } from '@/assets/bondEvaluationReport/fundType.svg';
import { ReactComponent as currentScaleSvg } from '@/assets/bondEvaluationReport/scale.svg';
import { ReactComponent as fundManagerSvg } from '@/assets/bondEvaluationReport/fundManager.svg';
import { ReactComponent as fundCompanySvg } from '@/assets/bondEvaluationReport/company.svg';
import { ReactComponent as foundDateSvg } from '@/assets/bondEvaluationReport/date.svg';
import backgroundImgLeft from '@/assets/etfCompass/basic-info-bg-1.svg';
import backgroundImgRight from '@/assets/etfCompass/basic-info-bg-2.svg';
import Icon from '@/components/icon';
import { formatHundredMillion } from '@/util/numberFormatter';
import Rate from '@/components/rate';
import { useGetBondFundInfoQuery } from '@/infra/api/bond';
import style from './index.module.less';

type InfoItem = {
  label: string;
  icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  value: any;
};
const BasicInfo = React.memo(() => {
  const { fundId } = useParams();
  const { data, isFetching } = useGetBondFundInfoQuery(fundId ?? '', { skip: !fundId });
  const {
    //   fundName, fundEvaluation,
    fundManager,
    fundTypeName,
    fundCompany,
    fundScale,
    foundDate,
    fundEvaluation
  } = data ?? {};
  //   console.log(fundName, fundEvaluation);
  const infos = useMemo<InfoItem[]>(
    () => [
      {
        label: getMessage('fundEvaluationLevel'),
        icon: rateSvg,
        key: 'fundEvaluationLevel',
        value: !isNil(fundEvaluation) ? <Rate value={fundEvaluation} /> : '--'
      },
      {
        label: getMessage('evaluationFundType'),
        icon: fundTypeSvg,
        value: fundTypeName
      },
      {
        label: getMessage('currentScale'),
        icon: currentScaleSvg,
        value: formatHundredMillion(+(fundScale ?? NaN))
      },
      {
        label: getMessage('fundManager'),
        icon: fundManagerSvg,
        value: fundManager
      },
      {
        label: getMessage('fundCompany'),
        icon: fundCompanySvg,
        value: fundCompany
      },
      {
        label: getMessage('foundDate'),
        icon: foundDateSvg,
        value: foundDate
      }
    ],
    [foundDate, fundManager, fundScale, fundTypeName, fundCompany, fundEvaluation]
  );

  return (
    <CardLayout loading={isFetching} title="" className={style.BasicInfoWrap}>
      <div className={style.BasicInfo}>
        <img className={style.BackgroundLeft} src={backgroundImgLeft} />
        <img className={style.BackgroundRight} src={backgroundImgRight} />
        {map((item: InfoItem) => (
          <div key={item.label} className={style.InfoItem}>
            <div className={style.LabelWrap}>
              <Icon svg={item.icon} />
              <div className={style.Label}>{item.label}</div>
            </div>
            <div className={style.Value}>{item.value || '--'}</div>
          </div>
        ))(infos)}
      </div>
    </CardLayout>
  );
});

export default BasicInfo;
