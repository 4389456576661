import React, { useMemo } from 'react';
import { getMessage } from '@betalpha/intl';
import { reverse } from 'lodash/fp';
import { percentage } from '@betalpha/stdlib/lib/numberFormatter';
import { useGetBondFundPerformanceQuery } from '@/infra/api/bond';
import PerformanceChart from '@/base-components/charts/performance-chart';
import CardLayout from '@/components/cardLayout';
import YearYieldTable from '@/views/fund-evaluation-home/components/recentPerformance/year-yield-table';
import style from '@/views/fund-evaluation-home/components/recentPerformance/index.module.less';
import ColorNumber from '../../../components/colorNumber';

interface HomeViewProps {
  fundId?: string;
}

const useColumns = () => {
  return useMemo(
    () => [
      { dataIndex: 'year', title: getMessage('period') },
      {
        dataIndex: 'five',
        title: '五星组合',
        render: (value: number) => <ColorNumber formatter={percentage} value={value} />
      },
      {
        dataIndex: 'one',
        title: '一星组合',
        render: (value: number) => <ColorNumber formatter={percentage} value={value} />
      },
      {
        dataIndex: 'benchmark',
        title: '纯债债基指数',
        render: (value: number) => <ColorNumber formatter={percentage} value={value} />
      }
    ],
    []
  );
};

export default React.memo<HomeViewProps>(() => {
  const { data, isFetching } = useGetBondFundPerformanceQuery();
  const { yearlyReturn } = data || {};
  const columns = useColumns();
  const dataSource = useMemo(() => {
    return reverse(
      yearlyReturn?.dates?.map((year, index) => ({
        year,
        five: yearlyReturn?.fiveStarFundCumulativeReturn?.[index],
        one: yearlyReturn?.oneStarFundCumulativeReturn?.[index],
        benchmark: yearlyReturn?.benchmarkCumulativeReturn?.[index]
      })) || []
    );
  }, [yearlyReturn]);

  return (
    <CardLayout title={<h1>五星债基近期表现</h1>} className={style.RecentPerformanceWrap} loading={isFetching}>
      <div className={style.ChartWrap}>
        <PerformanceChart
          option={{
            series: [
              {
                name: getMessage('fiveStarBondFund'),
                data: data?.performanceTrend?.fiveStarFundCumulativeReturn || []
              },
              {
                name: getMessage('oneStarBondFund'),
                data: data?.performanceTrend?.oneStarFundCumulativeReturn || []
              },
              {
                name: getMessage('twoSetsOfHedgingGains'),
                data: data?.performanceTrend?.benchmarkCumulativeReturn || []
              }
            ]
          }}
          dates={data?.performanceTrend?.dates || []}
          height={200}
        />
      </div>
      <YearYieldTable dataSource={dataSource || []} columns={columns} />
    </CardLayout>
  );
});
