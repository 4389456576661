import { marketTabItem } from '../fund-compass-home/contants';
import Profitability from './components/ability/profitability';
import Stability from './components/ability/stability';
import SelectionAbility from './components/ability/selectionAbility';
import AntiRiskAbility from './components/ability/antiRiskAbility';
import CompanyAbility from './components/ability/companyAbility';

// export const factorRatingsOrder = [
//   'fund_rank_performance', // "综合业绩",
//   'fund_rank_selection', // "个股选择能力",
//   'fund_rank_rebalance', // "调仓改善能力",
//   'fund_rank_capture', // "行情捕获能力",
//   'fund_rank_allocation' // "行业配置能力",
// ];

export const factorRatingsOrder = [
  'RETURN', // "收益能力",
  'SELECT_BOND', // "业绩稳定性",
  'PERFORMANCE_STABLE', // "择券能力",
  'ANTI_RISK', // "抗风险能力",
  'COMPANY_STRENGTH' // "公司实力",
];
export const abilityTabItems: marketTabItem[] = [
  {
    id: 'RETURN',
    name: 'incomeAbility',
    component: Profitability
  },
  {
    id: 'SELECT_BOND',
    name: 'selectionAbility',
    component: SelectionAbility
  },
  {
    id: 'PERFORMANCE_STABLE',
    name: 'performanceStability',
    component: Stability
  },
  {
    id: 'ANTI_RISK',
    name: 'antiRiskAbility',
    component: AntiRiskAbility
  },
  {
    id: 'COMPANY_STRENGTH',
    name: 'companyStrength',
    component: CompanyAbility
  }
];

export const performanceLevels: any = {
  HIGH: '优异',
  MIDDLE: '一般',
  LOWER: '较差'
};

export const rankLevels: any = {
  HIGH: '靠前',
  MIDDLE: '一般',
  LOWER: '靠后'
};
export const riskAdjReturnCompares: any = {
  HIGH: '高于',
  LOWER: '低于'
};
export const excessReturnLevels: any = {
  HIGH: '较强',
  MIDDLE: '一般',
  LOWER: '较弱'
};
export const winMarketLevels: any = {
  HIGH: '相对较高',
  MIDDLE: '一般',
  LOWER: '相对较低'
};
export const antiRiskLevels: any = {
  HIGH: '上游',
  MIDDLE: '中游',
  LOWER: '下游'
};
export const investmentResearchResourceLevel: any = {
  HIGH: '丰富',
  MIDDLE: '一般',
  LOWER: '有限'
};

export interface FundInfo {
  fundName: string;
}
