import React from 'react';
import { useTitle } from 'ahooks';
import Page from '@/components/page';
import ActionBar from '@/components/action-bar';
import NoticeBar from '@/components/notice-bar';
import { BOND_FUND } from '@/const';
import FundSearch from '@/components/fundSearch';
import style from '@/views/fund-evaluation-home/index.module.less';
import RecentPerformance from './recent-performance';
import HotFundPanel from './hot-fund-panel';

export default React.memo(() => {
  useTitle('SFinX | 债基稳斗士', { restoreOnUnmount: true });
  return (
    <Page
      actionBar={<ActionBar back={false} rootPath="/" title={<FundSearch type="BOND_FUND" />} />}
      className={style.HomeViewWrap}
    >
      <NoticeBar type={BOND_FUND} />
      <RecentPerformance />
      <HotFundPanel />
    </Page>
  );
});
