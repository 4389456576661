import React, { useMemo, useRef, useCallback } from 'react';
import { maxBy, prop } from 'lodash/fp';
import { getMessage } from '@betalpha/intl';
import { useParams } from '@betalpha/router';
import { percentage } from '@betalpha/stdlib/numberFormatter';
import { normalize } from '@betalpha/stdlib/base';
import CardLayout from '@/components/cardLayout';
import DismantlingTree from '@/base-components/charts/tree';
import { useGetBondDisassembleQuery } from '@/infra/api/bond';
import Collapse from '@/components/collapse';
import { treeDataType } from '@/interface/fundDetail';

import DetailModal, { DetailModalProps } from '@/components/detail-modal';
import { FundInfo } from '../../constants';
import style from './index.module.less';

export default React.memo<FundInfo>(({ fundName }) => {
  const { fundId } = useParams();
  const { isFetching, data: disassembleData } = useGetBondDisassembleQuery(fundId || '', {
    skip: !fundId
  });
  const {
    durationManagementReturn,
    termStructureReturn,
    creditSpreadReturn,
    valuationSpreadReturn,
    convertibleBondReturn
  } = disassembleData || {};

  const factorReturns = useMemo(
    () => [
      {
        name: getMessage('longManage'),
        value: durationManagementReturn,
        tip: '利率曲线水平变动为基金带来的收益率'
      },
      {
        name: getMessage('termStructure'),
        value: termStructureReturn,
        tip: '利率曲线斜率变动为基金带来的收益率'
      },
      {
        name: getMessage('creditSpread'),
        value: creditSpreadReturn,
        tip: '信用利差变动为基金带来的收益率'
      },
      {
        name: getMessage('valuationSpread'),
        value: valuationSpreadReturn,
        tip: '不同等级信用债之间利差变化为基金带来的收益率'
      },
      {
        name: getMessage('convertibleBond'),
        value: convertibleBondReturn,
        tip: '可转债收益变动为基金带来的收益率'
      }
    ],
    [durationManagementReturn, termStructureReturn, creditSpreadReturn, valuationSpreadReturn, convertibleBondReturn]
  );

  const FactorNameMap = useMemo(() => normalize(factorReturns, 'name'), [factorReturns]);
  const maxFactorReturn = useMemo(() => maxBy<typeof factorReturns[0]>('value')(factorReturns), [factorReturns]);

  const treeData = useMemo<treeDataType>(
    () => ({
      name: getMessage('fundAttribution'),
      value: disassembleData?.fundReturn,
      children: [
        {
          name: getMessage('activeAttribution'),
          value: disassembleData?.excessReturn
        },
        {
          name: getMessage('factorAttribution'),
          value: disassembleData?.factorReturn,
          children: factorReturns
        }
      ]
    }),
    [disassembleData, factorReturns]
  );

  const modalRef = useRef<{ open: (props: DetailModalProps) => void } | null>(null);
  const handleChartClick = useCallback(
    (param: any) => {
      param?.event?.stop?.();
      const { data } = param;
      if (data?.tip) {
        const tip = prop(`${data?.name}.tip`)(FactorNameMap);
        modalRef?.current?.open({ title: data?.name, content: tip });
      }
    },
    [FactorNameMap]
  );
  return (
    <CardLayout
      title={
        <div className={style.TitleWrap}>
          <span>业绩拆解</span>
          {/* <DetailModal
            ref={modalRef}
            title={getMessage('riskProbe')}
            content={getMessage('riskProbeDetail')}
            triggerClassName={style.Question}
          /> */}
        </div>
      }
      loading={isFetching}
    >
      <div className={style.RiskSummaryWrap}>
        <div className={style.Summary}>
          <Collapse title="基于Campisi模型的业绩拆解">
            {`${fundName}在过去三年的超额收益为${percentage(
              disassembleData?.excessReturn
            )}，而因子风格收益为${percentage(disassembleData?.factorReturn)}。在因子风格收益中，${
              maxFactorReturn?.name
            }部分为其带来了最多的收益，为${percentage(maxFactorReturn?.value)}。`}
          </Collapse>
        </div>
        <DismantlingTree treeData={treeData} height={320} handleChartClick={handleChartClick} />
        <DetailModal ref={modalRef} needTrigger={false} />
      </div>
    </CardLayout>
  );
});
