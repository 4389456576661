import { getMessage } from '@betalpha/intl';
import { fastProp, zipWithMinus } from '@betalpha/stdlib/base';
import { set, size } from 'lodash/fp';
import { useMemo } from 'react';
import cumulativeReturns from '@betalpha/stdlib/lib/quant/cumulativeReturns';
import { formatPercentage } from '@/util/numberFormatter';
import { ChartProps } from '@/base-components/charts/yieldTrendChart/type';
import { FundInfo } from '@/interface/bond';
import { CHART_COLOR } from '../fund-evaluation-detail/constants';

export const useGetLineChartData = (fundEvaluationReportInfo: FundInfo | undefined) => {
  const { fundName } = fundEvaluationReportInfo || {};
  const returns = fastProp<FundInfo['returns']>('returns')(fundEvaluationReportInfo || {});
  const { fundReturns: fundDailyReturns, dates, benchmarkReturns: benchmarkDailyReturns } = returns || {};

  const series = useMemo(() => {
    const fundReturns = cumulativeReturns(set(0, 0)(fundDailyReturns as any), true);
    const benchmarkReturns = cumulativeReturns(set(0, 0)(benchmarkDailyReturns as any), true);
    return [
      {
        name: getMessage('theFund'),
        color: CHART_COLOR[0],
        dates,
        returns: fundReturns
      },
      {
        name: getMessage('pureBondBaseIndex'),
        color: CHART_COLOR[1],
        dates,
        returns: benchmarkReturns
      },
      {
        name: getMessage('returnExcess'),
        color: CHART_COLOR[2],
        dates,
        returns: zipWithMinus(fundReturns, benchmarkReturns)
      }
    ];
  }, [benchmarkDailyReturns, dates, fundDailyReturns]);

  const chartOptions = useMemo<Partial<ChartProps>>(
    () => ({
      legendOptions: {
        left: 0,
        bottom: 'auto',
        top: 20,
        itemGap: 10,
        padding: 0,
        textStyle: { color: '#666666', fontSize: 12 }
      },
      gridOptions: { top: 64, bottom: 10, right: 0 },
      xAxisOptions: {
        boundaryGap: false,
        axisLabel: {
          color: '#666666',
          hideOverlap: false,
          interval: Math.floor(size(dates) / 2),
          fontFamily: 'DINAlternate',
          fontSize: 10
        },
        axisTick: { show: false },
        axisLine: { onZero: true }
      },
      yAxisOptions: {
        splitLine: {
          show: true,
          lineStyle: {
            color: '#ECECEC',
            type: 'dotted'
          }
        },
        axisLabel: {
          color: '#666666',
          fontFamily: 'DINAlternate',
          fontSize: 11,
          formatter: (value: number) => formatPercentage(value, { digit: 0 })
        }
      }
    }),
    [dates]
  );

  return { dates: dates || [], chartOptions, fundName, series };
};
