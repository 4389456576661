import { Table } from 'antd';
import { map, replace } from 'lodash/fp';
import React, { useMemo } from 'react';
import { fastProp } from '@betalpha/stdlib/base';
import { includeStr } from '@betalpha/stdlib/lib/base';
import { useParams } from '@betalpha/router';
import { getMessage } from '@betalpha/intl';
import { formatPercentage } from '@/util/numberFormatter';
import { SameTypeRankParam } from '@/interface/fundDetail';
import { useGetBondProfitabilityQuery, useGetBondFundInfoQuery } from '@/infra/api/bond';
import Collapse from '@/components/collapse';
import ColorNumber from '@/components/colorNumber';
import SimilarRanking from '@/views/fund-evaluation-detail/components/similarRanking';
import PerformanceTrendChart from '../../performanceTrend/performanceTrendChart';
import { performanceLevels, excessReturnLevels, rankLevels, riskAdjReturnCompares, FundInfo } from '../../../constants';

import style from './index.module.less';

const factorNameMap = {
  THREE_ANUALL: '3年年化收益',
  STUTZER: 'Stutzer',
  CAMPISI: 'Compisi模型Alpha'
};
const colorNumberRender = (val: string | number) => {
  if (typeof val === 'string' && includeStr('%')(val)) {
    return <ColorNumber value={+replace('%')('')(val) / 100} formatter={formatPercentage} />;
  }
  return <ColorNumber value={val as number} />;
};

export default React.memo<FundInfo>(({ fundName }) => {
  const columns = [
    {
      title: getMessage('assetType'),
      dataIndex: 'name',
      key: 'name',
      width: 150,
      align: 'left'
    },
    {
      title: getMessage('FundPerformance'),
      dataIndex: 'fundPerformance',
      key: 'fundPerformance',
      width: 150,
      align: 'right',
      render: colorNumberRender
    },
    {
      title: '同类均值',
      dataIndex: 'marketPerformance',
      key: 'marketPerformance',
      width: 150,
      align: 'right',
      render: colorNumberRender
    },
    {
      title: getMessage('rankByCategory'),
      dataIndex: 'sameTypeFactorRank',
      key: 'sameTypeFactorRank',
      width: 150,
      align: 'right',
      render: (data: SameTypeRankParam) => {
        return (
          <span className={style.RankByCategory}>
            <span className={style.RankNum}>{data?.rank}</span>/{data?.count}
          </span>
        );
      }
    }
  ];
  const { fundId } = useParams();
  const { data: incomeData } = useGetBondProfitabilityQuery(fundId || '', { skip: !fundId });
  const { performanceLevel, stutzer, rankLevel, excessReturnLevel, riskAdjReturnCompare, factorPerformance } =
    incomeData || {
      performanceLevel: '',
      rankLevel: '',
      excessReturnLevel: '',
      riskAdjReturnCompare: '',
      factorPerformance: []
    };

  const { data: fundEvaluationReportInfo } = useGetBondFundInfoQuery(fundId || '', { skip: !fundId });
  const dataSource = useMemo(
    () =>
      map(({ type, fund, sameTypeAvg, sameTypeCount, sameTypeRank }) => ({
        name: fastProp(type)(factorNameMap),
        fundPerformance: formatPercentage(fund),
        marketPerformance: formatPercentage(sameTypeAvg),
        sameTypeFactorRank: {
          rank: sameTypeRank,
          count: sameTypeCount
        }
      }))(factorPerformance),
    [factorPerformance]
  );

  return (
    <div className={style.ComprehensivePerformance}>
      <SimilarRanking rank={incomeData?.rank} count={incomeData?.sameTypeCount} />
      <div className={style.Summary}>
        <Collapse title={`${fundName}在过去三年的业绩整体表现${performanceLevels?.[performanceLevel] || '--'}`}>
          {`${fundName}在过去三年的业绩整体表现${
            performanceLevels?.[performanceLevel] || '--'
          }。过去三年年化收益为${formatPercentage(incomeData?.threeYearAnnualReturn)}，在同类基金中排名${
            rankLevels[rankLevel] || '--'
          }；Stutzer指标为${formatPercentage(stutzer)}，其风险调整收益${
            riskAdjReturnCompares[riskAdjReturnCompare] || '--'
          }同类平均水平；基于Campisi模型计算的Alpha为${formatPercentage(incomeData?.alpha)}，获取超额收益的能力${
            excessReturnLevels[excessReturnLevel] || '--'
          }。`}
        </Collapse>
      </div>
      <PerformanceTrendChart fundEvaluationReportInfo={fundEvaluationReportInfo} showDate />
      <div className={style.TableWrap}>
        <div className={style.Divider} />
        <Table
          columns={columns as any}
          dataSource={dataSource}
          rowKey="name"
          pagination={false}
          bordered={false}
          className={style.PerformanceTable}
        />
      </div>
    </div>
  );
});
