import React from 'react';
import { getMessage } from '@betalpha/intl';
import { useParams } from '@betalpha/router';
import CardLayout from '@/components/cardLayout';
import { useGetBondFundInfoQuery } from '@/infra/api/bond';
// import { FundInfo } from '@/interface/bond';
import PerformanceTrendChart from './performanceTrendChart';

export default React.memo(() => {
  const { fundId } = useParams();
  const { data, isFetching } = useGetBondFundInfoQuery(fundId || '', { skip: !fundId });

  return (
    <CardLayout title={getMessage('performanceTrend')} loading={isFetching}>
      <PerformanceTrendChart fundEvaluationReportInfo={data} showDate />
    </CardLayout>
  );
});
