import React, { useMemo } from 'react';
import { getMessage } from '@betalpha/intl';
import { useParams } from '@betalpha/router';
import { first, isEmpty, last } from 'lodash/fp';
import { EChartsOption } from 'echarts';
import { percentage } from '@betalpha/stdlib/numberFormatter';
import { useGetBondSelectionAbilityQuery } from '@/infra/api/bond';
import Collapse from '@/components/collapse';
import SimilarRanking from '@/views/fund-evaluation-detail/components/similarRanking';
import EmptyContent from '@/components/emptyContent';
import { formatPercentage } from '@/util/numberFormatter';
import BaseEcharts from '@/components/baseEcharts';
import { riskAdjReturnCompares, FundInfo } from '../../../constants';
import style from './index.module.less';

export const useGetLineChartData = (data: any) => {
  const { contributions, sameTypeContributions, dates } = data?.data || {};

  const chartOptions = useMemo<EChartsOption>(
    () => ({
      legend: {
        left: 5,
        bottom: 'auto',
        top: 20,
        itemGap: 10,
        padding: 0,
        textStyle: { color: '#666666', fontSize: 12 }
      },
      grid: { top: 54, bottom: 20, right: 10, left: 40 },
      xAxis: {
        boundaryGap: true,
        axisLabel: {
          color: '#666666',
          hideOverlap: false,
          //   interval: Math.floor(size(dates) / 2),
          fontWeight: 'normal',
          fontSize: 10
        },
        axisTick: { show: false },
        axisLine: {
          onZero: true
        },
        data: dates
      },
      yAxis: {
        splitLine: {
          show: true,
          lineStyle: {
            color: '#ECECEC',
            type: 'dotted'
          }
        },
        axisLabel: {
          color: '#666666',
          fontFamily: 'DINAlternate',
          fontSize: 10,
          fontWeight: 'normal',
          formatter: (value: number) => formatPercentage(value, { digit: 2 })
        }
      },
      graphic: {
        top: 20,
        type: 'text',
        right: 0,
        style: {
          text: `（${first(dates)}~${last(dates)}）`,
          fontSize: 9,
          lineHeight: 12,
          fill: '#999999',
          fontFamily: 'PingFangSC-Semibold, PingFang SC',
          fontWeight: 600
        }
      },
      series: [
        {
          name: 'T-M模型Alpha',
          type: 'bar',
          data: contributions,
          label: {
            show: false
          }
        },
        {
          name: '同类平均',
          type: 'bar',
          data: sameTypeContributions,
          label: {
            show: false
          }
        }
      ]
    }),
    [dates, contributions, sameTypeContributions]
  );

  return chartOptions;
};

export default React.memo<FundInfo>(({ fundName }) => {
  const { fundId } = useParams();
  const { data } = useGetBondSelectionAbilityQuery(fundId || '', { skip: !fundId });

  const { dates } = data?.data || {};
  const { selectionCompare } = data || { selectionCompare: '' };

  const options = useGetLineChartData(data);

  return (
    <div className={style.ComprehensivePerformance}>
      <SimilarRanking rank={data?.rank} count={data?.sameTypeCount} />
      <div className={style.Summary}>
        <Collapse title={`${fundName}的个券选择能力整体${riskAdjReturnCompares[selectionCompare] || '--'}同类平均水平`}>
          {`${fundName}的个券选择能力整体${
            riskAdjReturnCompares[selectionCompare] || '--'
          }同类平均水平， 根据T-M模型，其过去三年个券选择贡献为${percentage(
            data?.threeYearSelectionContribution
          )}，在同类中排名为${data?.threeYearSelectionContributionRank}/${data?.sameTypeCount}`}
        </Collapse>
      </div>
      {isEmpty(dates) ? (
        <EmptyContent className={style.EmptyContent}>
          <strong>{getMessage('noData')}</strong>
        </EmptyContent>
      ) : (
        <BaseEcharts options={options} height={240} />
      )}
    </div>
  );
});
