import React, { useCallback } from 'react';
import { useNavigate } from '@betalpha/router';
import { useGetHotBondFundsQuery } from '@/infra/api/bond';
import CardLayout from '@/components/cardLayout';
import HotFund from '@/views/fund-evaluation-home/components/popularFund/hot';
import style from '@/views/fund-evaluation-home/components/popularFund/index.module.less';

const HotFundPanel = () => {
  const { data, isFetching } = useGetHotBondFundsQuery();
  const navigateTo = useNavigate();
  const handleFundClick = useCallback(
    (fundId: string) => {
      navigateTo(fundId);
    },
    [navigateTo]
  );

  return (
    <CardLayout className={style.PopularFundWrap} title={<h1>热门五星债基</h1>} loading={isFetching}>
      <HotFund data={data} onClick={handleFundClick} />
    </CardLayout>
  );
};

export default React.memo(HotFundPanel);
