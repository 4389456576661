import { getMessage } from '@betalpha/intl';
import cumulativeReturns from '@betalpha/stdlib/lib/quant/cumulativeReturns';
import { EChartsOption } from 'echarts';
import { map, set, toLower, isEmpty, orderBy, prop, size, findIndex, isNil } from 'lodash/fp';
import React, { useMemo } from 'react';
import { mapIndexed } from '@betalpha/stdlib/base';
import classNames from 'classnames';
import { formatPercentage } from '@/util/numberFormatter';
import { MarketRankDetailsParam } from '@/interface/fundDetail';
import {
  getDateOfQuarter,
  getTextColor,
  getTooltipColorInfo
} from '@/views/fund-evaluation-detail/components/ability/marketCaptureAbility/contants';
import { useCalculatePosition } from '@/views/fund-evaluation-detail/components/ability/marketCaptureAbility/hooks';
import BaseEcharts from '../../../../../components/baseEcharts';
import style from './index.module.less';

export default React.memo(
  ({
    name,
    type,
    markAreaColor,
    // fundId,
    className,
    marketCapabilityDetail,
    fundEvaluationReportInfo
  }: {
    name: string;
    type: 'RISE' | 'FALL';
    markAreaColor: string;
    // fundId: string;
    className?: string;
    marketCapabilityDetail: any;
    fundEvaluationReportInfo: any;
  }) => {
    const returns = prop('returns')(fundEvaluationReportInfo);
    const { fundReturns: fundDailyReturns, dates, benchmarkReturns: benchmarkDailyReturns } = returns || {};

    const { marketFallRankingDetails, sameTypeCount, threeYearMaxDrawdownPeriod } = marketCapabilityDetail || {};

    const riskRankingDetails = useMemo(() => {
      if (!marketFallRankingDetails) return marketFallRankingDetails;
      return orderBy<MarketRankDetailsParam>('startDate', 'asc')(marketFallRankingDetails);
    }, [marketFallRankingDetails]);
    const markAreaData = useMemo(() => {
      if (!riskRankingDetails) return riskRankingDetails;
      return map((item: MarketRankDetailsParam) => [
        {
          xAxis: item?.startDate
        },
        {
          xAxis: item?.endDate
        }
      ])(riskRankingDetails);
    }, [riskRankingDetails]);

    const options = useMemo<EChartsOption>(
      () => ({
        graphic: {
          type: 'text',
          right: 0,
          bottom: 0
        },
        grid: {
          left: 60,
          top: 20,
          bottom: 35,
          right: 30
        },
        legend: isNil(threeYearMaxDrawdownPeriod)
          ? {
              bottom: 0,
              top: 'auto',
              itemGap: 30,
              padding: 0,
              itemWidth: 20,
              itemHeight: 3
            }
          : [
              {
                left: '20%',
                bottom: 0,
                top: 'auto',
                itemGap: 30,
                padding: 0,
                itemWidth: 20,
                itemHeight: 3,
                data: [
                  {
                    name: getMessage('theFund'),
                    icon: 'rect'
                  }
                ]
              },
              {
                bottom: 0,
                top: 'auto',
                left: 'center',
                itemGap: 100,
                padding: 0,
                itemWidth: 20,
                itemHeight: 3,
                data: [
                  {
                    name: '纯债债基指数',
                    icon: 'rect'
                  }
                ]
              },
              {
                itemWidth: 20,
                itemHeight: 12,
                left: '65%',
                bottom: 0,
                top: 'auto',
                itemGap: 30,
                padding: 0,
                data: [
                  {
                    name: '三年内最大回撤',
                    icon: 'rect'
                  }
                ]
              }
            ],
        visualMap: {
          type: 'piecewise',
          show: false,
          //   z: 100,
          dimension: 0,
          seriesIndex: 2,
          right: '30%',
          itemWidth: 10,
          pieces: [
            {
              gt: findIndex((date) => threeYearMaxDrawdownPeriod?.from === date)(dates),
              lt: findIndex((date) => threeYearMaxDrawdownPeriod?.to === date)(dates),
              color: '#2E9482'
              //   label: "三年内最大回撤",
            }
          ]
        },
        xAxis: {
          zlevel: 1,
          data: dates,
          axisLine: { onZero: true },
          axisLabel: {
            color: '#666666',
            fontWeight: 'normal',
            interval: (index, number) => {
              if (index === 0 || index === size(dates) - 1) {
                return number;
              }
              return false;
            },
            fontFamily: 'DINAlternate',
            fontSize: 10
          }
        },
        yAxis: {
          axisLabel: {
            color: '#666666',
            fontWeight: 'normal'
          }
        },
        series: isNil(threeYearMaxDrawdownPeriod)
          ? [
              {
                name: getMessage('theFund'),
                type: 'line',
                data: cumulativeReturns(set(0, 0)(fundDailyReturns), true),
                markArea: {
                  silent: true,
                  itemStyle: { color: markAreaColor },
                  data: markAreaData as any
                }
              },
              {
                name: '纯债债基指数',
                type: 'line',
                data: cumulativeReturns(set(0, 0)(benchmarkDailyReturns), true)
              }
            ]
          : [
              {
                name: getMessage('theFund'),
                type: 'line',
                data: cumulativeReturns(set(0, 0)(fundDailyReturns), true),
                markArea: {
                  silent: true,
                  itemStyle: { color: markAreaColor },
                  data: markAreaData as any
                }
              },
              {
                name: '纯债债基指数',
                type: 'line',
                data: cumulativeReturns(set(0, 0)(benchmarkDailyReturns), true)
              },
              {
                name: '三年内最大回撤',
                color: '#2E9482',
                type: 'line',
                data: cumulativeReturns(set(0, 0)(fundDailyReturns), true),
                areaStyle: {
                  origin: 'start'
                  //   color: "transparent",
                },
                lineStyle: {
                  color: 'transparent'
                }
                // data: cumulativeReturns(set(0, 0)(fundDailyReturns), true)
              }
            ]
      }),
      [dates, fundDailyReturns, markAreaColor, markAreaData, benchmarkDailyReturns, threeYearMaxDrawdownPeriod]
    );

    const tooltipColorInfo = useMemo(() => getTooltipColorInfo(type), [type]);
    const { onChartReady, tooltipPositionInfo, triangleNode } = useCalculatePosition(
      markAreaColor,
      tooltipColorInfo.background
    );
    return (
      <div className={classNames(className, style.TableChartWrap)}>
        <div className={style.TableTitle}>
          {name}（
          {getMessage('highestIncreaseThreeQuarters', {
            riseOrFall: getMessage(toLower(type))
          })}
          ）
        </div>
        <div className={style.TableWrap}>
          <div className={style.TableCol}>
            <div className={style.Col}>{getMessage('periodIncome')}：</div>
            <div className={style.Col}>{getMessage('excessIncome')}：</div>
            <div className={style.Col}>{getMessage('similarRankings')}：</div>
          </div>
          {!isEmpty(dates) && tooltipPositionInfo && (
            <div className={style.DataColWrap}>
              {mapIndexed((item: MarketRankDetailsParam, index: number) => {
                return (
                  <div
                    className={classNames(style.TableCol, style.DataCol)}
                    style={{
                      left: tooltipPositionInfo[index]?.left,
                      backgroundColor: tooltipColorInfo.background
                    }}
                    key={item.startDate}
                  >
                    <div
                      className={style.ColTitle}
                      style={{
                        backgroundColor: tooltipColorInfo.title,
                        borderBottom: `1px solid ${tooltipColorInfo.border}`
                      }}
                    >
                      {getDateOfQuarter(item.endDate)}
                    </div>
                    <div className={style.ColContent}>
                      <div className={style.Col} style={{ color: getTextColor(item.sectionReturn) }}>
                        {formatPercentage(item?.sectionReturn)}
                      </div>
                      <div className={style.Col} style={{ color: getTextColor(item.excessReturn) }}>
                        {formatPercentage(item?.excessReturn)}
                      </div>
                      <div className={style.Col} style={{ color: '#999999', fontWeight: 500 }}>
                        <span style={{ fontWeight: 600, color: '#000' }}>{item?.sameTypeRank}</span>/{sameTypeCount}
                      </div>
                    </div>
                  </div>
                );
              })(riskRankingDetails)}
              {triangleNode}
            </div>
          )}
        </div>
        <div className={style.ChartWrap}>
          {!isEmpty(dates) && <BaseEcharts options={options} onChartReady={onChartReady} height={200} />}
        </div>
      </div>
    );
  }
);
