import React, { useCallback } from 'react';
import { getMessage } from '@betalpha/intl';
import { isEmpty, map } from 'lodash/fp';
import { useNavigate, useParams } from '@betalpha/router';
import { percentage } from '@betalpha/stdlib/lib/numberFormatter';
import { useGetBondCompanyAbilityQuery } from '@/infra/api/bond';
import Rate from '@/components/rate';
import EmptyContent from '@/components/emptyContent';
import ColorNumber from '@/components/colorNumber';
import Collapse from '@/components/collapse';
import { formatHundredMillion } from '@/util/numberFormatter';
import SimilarRanking from '@/views/fund-evaluation-detail/components/similarRanking';
import { FundInfo, investmentResearchResourceLevel, antiRiskLevels } from '../../../constants';
import style from './index.module.less';

interface BondDetail {
  rate: number;
  name: string;
  scale: number;
  latest3YearReturn: number;
  fundId?: string;
}
export default React.memo<FundInfo>(({ fundName }) => {
  const { fundId } = useParams();
  const { data } = useGetBondCompanyAbilityQuery(fundId || '', { skip: !fundId });
  const navigateTo = useNavigate();

  const onClick = useCallback(
    (id: any) => {
      navigateTo(`/${id}`);
    },
    [navigateTo]
  );
  if (isEmpty(data)) return <EmptyContent message={getMessage('noData')} />;

  return (
    <div className={style.PopularFundWrap}>
      <SimilarRanking rank={data?.rank} count={data?.sameTypeCount} />
      <div className={style.Summary}>
        <Collapse
          title={`${data?.companyName}的投研团队的资源${
            investmentResearchResourceLevel[data.investmentResearchResourceLevel] || '--'
          }`}
        >
          {`${fundName}所属的${data?.companyName}管理债券型基金的总规模为${formatHundredMillion(
            +(data?.scale ?? NaN)
          )}元，在行业中处于${antiRiskLevels[data.scaleLevel] || '--'}水平，表明基金公司的投研团队的资源${
            investmentResearchResourceLevel[data.investmentResearchResourceLevel] || '--'
          }`}
        </Collapse>
      </div>
      <div className={style.TableTitle}>公司债基星级得分TOP5</div>
      {data?.top5BondFundOfRate ? (
        <div className={style.ListWrap}>
          {map((item: BondDetail) => (
            <div
              key={item?.fundId}
              className={style.Panel}
              onClick={(e) => {
                e.stopPropagation();
                onClick(item?.fundId);
              }}
            >
              <Rate value={item.rate} wrapClassName={style.wrapClassName} />
              <div className={style.YieldWrap}>
                <ColorNumber value={item.latest3YearReturn} formatter={percentage} className={style.ColorNumber} />
                <div className={style.RecentThreeYearYield}>{getMessage('recentThreeYearYield')}</div>
              </div>
              <div className={style.FundInfoWrap}>
                <div className={style.FundName}>{item.name}</div>
                <div className={style.RecentThreeYearYield}>规模：{formatHundredMillion(+(item?.scale ?? NaN))}</div>
              </div>
            </div>
          ))(data?.top5BondFundOfRate)}
        </div>
      ) : (
        <EmptyContent message={getMessage('noData')} style={{ height: 236 }} />
      )}
    </div>
  );
});
