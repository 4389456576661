import React, { useCallback, useMemo, useRef } from 'react';
import { getMessage } from '@betalpha/intl';
import { flow, map, floor, prop, split, first, isEmpty, compact } from 'lodash/fp';
import { FactorRatingItemType } from '@/interface/fundDetail';
import DetailModal, { DetailModalProps } from '@/components/detail-modal';
import EmptyContent from '@/components/emptyContent';
import BaseEcharts from '@/components/baseEcharts';
import { useGetDefaultRadarOptions } from '@/views/fund-evaluation-detail/hooks';
// import style from './index.module.less';

interface RateProp {
  sortedFactorRatings: any;
  factorRatingNameMap: any;
}
export default React.memo<RateProp>(({ sortedFactorRatings, factorRatingNameMap }) => {
  const dataSource = useMemo(() => {
    return [
      {
        name: getMessage('theFund'),
        value: map((item: FactorRatingItemType) => item?.fundFactorRating)(sortedFactorRatings)
      },
      {
        name: getMessage('averageOfTheSameType'),
        value: map((item: FactorRatingItemType) => item?.sameTypeFundFactorRating)(sortedFactorRatings)
      }
    ];
  }, [sortedFactorRatings]);

  const options = useGetDefaultRadarOptions({
    radar: {
      triggerEvent: true,
      radius: 50,
      indicator: map((item: FactorRatingItemType) => ({
        name: item?.factorName,
        max: 5
      }))(sortedFactorRatings),
      axisName: {
        formatter: (name: string | undefined) => {
          const ratingNum = flow(prop(`${name}.fundFactorRating`), floor)(factorRatingNameMap) || 0;
          let res = `${name}{question|}\n`;
          const starStr = `{star|}`;
          // eslint-disable-next-line no-plusplus
          for (let index = 0; index < ratingNum; index++) {
            res += starStr;
            if (index < ratingNum - 1) res += ' ';
          }
          return res;
        }
      }
    },
    series: [
      {
        z: 2,
        data: [dataSource[0]]
      },
      {
        z: 1,
        data: [dataSource[1]]
      }
    ]
  });

  const modalRef = useRef<{ open: (props: DetailModalProps) => void } | null>(null);
  const handleChartClick = useCallback(
    (param: any) => {
      param?.event?.stop?.();
      const { targetType } = param;
      if (targetType === 'axisName') {
        const name = flow(prop('name'), split('{question|}'), first)(param);
        const factorCode = prop(`${name}.factorCode`)(factorRatingNameMap);
        modalRef?.current?.open({ title: name, content: getMessage(`profitability_${factorCode}_detail`) });
      }
    },
    [factorRatingNameMap]
  );

  return (
    <>
      <div>
        {!isEmpty(compact(sortedFactorRatings)) ? (
          <BaseEcharts events={{ click: handleChartClick }} options={options} height={236} />
        ) : (
          <EmptyContent message={getMessage('noData')} style={{ height: 236 }} />
        )}
      </div>
      <DetailModal ref={modalRef} needTrigger={false} />
    </>
  );
});
