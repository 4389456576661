import React from 'react';
import { getMessage } from '@betalpha/intl';
import { useParams } from '@betalpha/router';
import { isEmpty } from 'lodash/fp';
import { useGetBondFundInfoQuery, useGetBondAntiRiskAbilityQuery } from '@/infra/api/bond';
import Collapse from '@/components/collapse';
import SimilarRanking from '@/views/fund-evaluation-detail/components/similarRanking';
import EmptyContent from '@/components/emptyContent';
import { FundInfo, antiRiskLevels } from '../../../constants';
import RiskAbilityChart from './riskAbilityChart';
import style from './index.module.less';

export default React.memo<FundInfo>(({ fundName }) => {
  const { fundId } = useParams();
  const { data: fundEvaluationReportInfo } = useGetBondFundInfoQuery(fundId || '', { skip: !fundId });
  const { data } = useGetBondAntiRiskAbilityQuery(fundId || '', { skip: !fundId });

  const { antiRiskLevel, sameTypeCount, rank } = data || { antiRiskLevel: '' };
  const { dates } = fundEvaluationReportInfo?.returns || {};

  //   const { data: fundEvaluationReportInfo } = useGetEvaluationReportInfoQuery(fundId || '', { skip: !fundId });

  return (
    <div className={style.ComprehensivePerformance}>
      <SimilarRanking rank={rank} count={sameTypeCount} />
      <div className={style.Summary}>
        <Collapse title={`${fundName}的抗风险能力在同类基金中处于${antiRiskLevels[antiRiskLevel] || '--'}水平`}>
          {`${fundName}的抗风险能力在同类基金中处于${
            antiRiskLevels[antiRiskLevel] || '--'
          }水平。过去三年里，基金的最大回撤在同类产品中排名为
          ${data?.threeYearMaxDrawdownRank}/${sameTypeCount} ，下跌抵抗率在同类产品中排名为${
            data?.threeYearFallResistance
          }/${sameTypeCount}。`}
        </Collapse>
      </div>
      {isEmpty(dates) ? (
        <EmptyContent className={style.EmptyContent}>
          <strong>{getMessage('noData')}</strong>
        </EmptyContent>
      ) : (
        <RiskAbilityChart
          name={getMessage('marketFallStageRank')}
          type="FALL"
          fundEvaluationReportInfo={fundEvaluationReportInfo}
          marketCapabilityDetail={data}
          markAreaColor="#D5EDDF"
          className={style.MarketAbilityFallChart}
        />
      )}
    </div>
  );
});
